@font-face {
  font-family: 'Effra';
  src: url('./effra_light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Effra';
  src: url('./effra_regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Effra';
  src: url('./effra_medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Effra';
  src: url('./effra_heavy.ttf') format('truetype');
  font-weight: 700;
}
