.p-sidebar {
  background-color: $primaryColor;

  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 0;
  }

  .p-sidebar-footer {
    display: flex;
    justify-content: center;
    padding: 0 0 75px 0;
  }
}
