// Theme Specific Variables
$primaryColor: #275d38;
$primaryDarkColor: #1f4a2d;
$primaryDarkerColor: #173822;
$primaryTextColor: #ffffff;
$secondaryTextColor: #6c757d;
$error: #f04438;

$colors: (
  'blue': #2196f3,
  'green': #4caf50,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #e91e63,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': #f57c00,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  'red': #f04438,
  'primary': $primaryColor,
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  font-family: 'Effra', sans-serif;
  --font-family: 'Effra', sans-serif;
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #{$primaryTextColor};
  --text-color-secondary: #{$secondaryTextColor};
  --primary-color: #{$primaryColor};
  --primary-color-dark: #{$primaryDarkColor};
  --primary-color-darker: #{$primaryDarkerColor};
  --primary-color-text: #ffffff;
  --error-color: #{$error};
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eaecf0;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  color-scheme: light;
  --shadow-md: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
